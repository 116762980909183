<template>
  <div>
    <b-card id="cursos_asignaturas"
      class="mb-1"
    >
      <avancesFiltro
        @changeCurso="cargaAlumnos"
        @changeAsignatura="showEjesObjetivos"
      />
    </b-card>

    <b-card id="ejes_objetivos"
      v-if="mostrarEjesObjetivos"
      aria-hidden="true"
      class="mb-1"
      no-body
    >
      <avancesEjesObjetivos
        v-if="mostrarEjesObjetivos"
        :idAsignatura.sync="idAsignaturaSelected"
        :idPeriodoActivo.sync="idPeriodoActivo"
        :idCursoSelected.sync="idCursoSelected"
        @showObjetivo="showObjetivo"
        @showAlumnosIndicadores="showAlumnosIndicadores"
      />
    </b-card>

    <b-card id="objetivo_descripcion"
      v-if="objetivo"
      :class="objetivo.puntajes_indicadores >= 1 || objetivo.puntajes_indicadores_personalizado >= 1
        ? 'mb-1 text-center bg-gradient-success'
        : 'mb-1 text-center bg-gradient-primary' "
    >
      <avancesObjetivo
        class="mb-2"
        :objetivo.sync="objetivo"
      />
    </b-card>

    <b-overlay
      :show="cargandoAlumnos"
      spinner-variant="primary"
      :class="`${cargandoAlumnos ? 'mt-5' : ''}`"
      variant="semi-dark"
    >
      <b-card id="alumnos_indicadores_table"
        v-if="mostrarAlumnosIndicadores"
        aria-hidden="true"
        class="text-center pt-0 mb-0"
        no-body
      >
        <b-tabs fill>
          <b-tab active>
            <template #title>
              <feather-icon icon="UsersIcon" />
              <span>Alumnos</span>
            </template>

            <avancesAlumnosIndicadores

              :idObjetivo="objetivo.id"
              :objetivo="objetivo"
              :indicadores.sync="indicadores"
              :indicadorPersonalizado.sync="indicadorPersonalizado"
              :alumnos="alumnos"
              :puntajesAlumnos.sync="puntajesAlumnos"
              :idPeriodoActivo="idPeriodoActivo"
              :idCursoSelected="idCursoSelected"
              :idAsignaturaSelected="idAsignaturaSelected"
            />

          </b-tab>
          <b-tab>

            <template #title>
              <feather-icon icon="PaperclipIcon" />
              <span>Indicadores personalizados</span>
            </template>

            <indicadores
              v-if="mostrarAlumnosIndicadores"
              :objetivo="objetivo"
              :idPeriodoActivo="idPeriodoActivo"
              :idCurso="idCursoSelected"
              :idAsignaturaSelected="idAsignaturaSelected"
            />

          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BOverlay, BAlert, BTabs, BTab, BCardText,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

import avancesFiltro from './components/AvancesFiltro.vue'
import avancesEjesObjetivos from './components/AvancesEjesObjetivos.vue'
import avancesObjetivo from './components/AvancesObjetivo.vue'
import avancesAlumnosIndicadores from './components/AvancesAlumnosIndicadores.vue'
import indicadores from './components/indicadoresPersonalizados/Indicadores.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BAlert,
    BTabs,
    BTab,
    BCardText,
    avancesFiltro,
    avancesEjesObjetivos,
    avancesObjetivo,
    avancesAlumnosIndicadores,
    indicadores,
  },
  data() {
    return {
      // Globals
      idEstablecimientoActivo: null,
      idPeriodoActivo: null,
      idCursoSelected: null,
      idAsignaturaSelected: null,
      fetchPuntajesCount: 0,
      cargandoAlumnos: false,

      // Filtros
      tipoEnseñanzas: [],
      cursos: [],
      asignaturas: [],

      // Ejes y Objetivos
      mostrarEjesObjetivos: false,
      idObjetivo: null,

      // AlumnosIndicadores
      mostrarAlumnosIndicadores: false,
      alumnos: [],
      indicadores: [],
      indicadorPersonalizado: [],
      puntajesAlumnos: [],
      objetivo: null,
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',

      getIndicadoresObjetivo: 'indicadores/getIndicadoresObjetivo',
      getIndicadorPersonalizadoObjetivoAprobados: 'indicadorPersonalizado/getIndicadorPersonalizadoObjetivoAprobados',
      getAlumnosCurso: 'alumnos/getAlumnosCurso',
      getPuntajesIndicadores: 'puntajesIndicadores/getPuntajesIndicadores',
      getObjetivosAsignatura: 'objetivos/getObjetivosAsignatura',
    }),
  },
  watch: {
    getObjetivosAsignatura(objetivos) {
      this.setObjetivo(objetivos)
    },
    getIndicadorPersonalizadoObjetivoAprobados() {
      this.indicadorPersonalizado = this.getIndicadorPersonalizadoObjetivoAprobados
      this.cargarPuntajesIndicadores(this.objetivo)
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions({
      fetchIndicadoresObjetivo: 'indicadores/fetchIndicadoresObjetivo',
      fetchIndicadoresPersonalizadosObjetivoAprobados: 'indicadorPersonalizado/fetchIndicadoresPersonalizadosObjetivoAprobados',
      fetchAlumnosCurso: 'alumnos/fetchAlumnosCurso',
      fetchPuntajesIndicadores: 'puntajesIndicadores/fetchPuntajesIndicadores',
      fetchPuntajesIndicadoresTransformacion: 'puntajesIndicadores/fetchPuntajesIndicadoresTransformacion',
    }),
    initialize() {
      this.idPeriodoActivo = this.getUser.idPeriodoActivo === null
                              ? 3
                              : this.getUser.idPeriodoActivo
    },
    setObjetivo(objetivos) {
      if (this.objetivo) {
        const objetivo = objetivos.find(o => o.id === this.objetivo.id)
        this.objetivo = objetivo
      }
    },
    reset() {
      this.mostrarEjesObjetivos = false
      this.mostrarAlumnosIndicadores = false
      this.objetivo = null
      this.puntajesAlumnos = null
      this.cargandoAlumnos = false
    },
    cargaAlumnos(idCurso) {
      this.reset()
      this.alumnos = []
      this.fetchAlumnosCurso(idCurso).then(() => {
        this.alumnos = this.getAlumnosCurso
      })
      this.fetchPuntajesIndicadoresTransformacion()
    },

    showEjesObjetivos(params) {
      this.reset()
      const {
        cursoSelected,
        asignaturaSelected,
      } = params
      if (cursoSelected !== null && asignaturaSelected !== null) {
        this.idCursoSelected = cursoSelected
        this.idAsignaturaSelected = asignaturaSelected
        this.mostrarEjesObjetivos = true
        this.mostrarAlumnosIndicadores = false
      }
    },
    showObjetivo(objetivo) {
      this.cargandoAlumnos = true
      this.objetivo = objetivo
    },
    showAlumnosIndicadores(objetivo) {
      this.mostrarAlumnosIndicadores = false
      this.indicadores = []
      this.puntajesAlumnos = []
      this.fetchsIndicadores(objetivo)
    },

    fetchsIndicadores(objetivo) {
      const datosIndicadores = {
        idObjetivo: objetivo.id,
        tipo: objetivo.tipo,
      }
      this.fetchIndicadoresObjetivo(datosIndicadores).then(() => {
        this.indicadores = this.getIndicadoresObjetivo
      })

      const datosIndicadoresPersonalizados = {
        idObjetivo: objetivo.id,
        idCurso: this.idCursoSelected,
        idPeriodo: this.idPeriodoActivo,
        tipoObjetivo: objetivo.tipo,
      }
      this.fetchIndicadoresPersonalizadosObjetivoAprobados(datosIndicadoresPersonalizados).then(() => {
        this.fetchPuntajesCount = 1
      })

      this.cargarPuntajesIndicadores(objetivo)
    },
    cargarPuntajesIndicadores(objetivo) {
      const datosIndicadores = {
        idObjetivo: objetivo.id,
        tipo: objetivo.tipo,
      }
      const datosPuntajes = {
        idPeriodo: this.idPeriodoActivo,
        idCurso: this.idCursoSelected,
        idAsignatura: this.idAsignaturaSelected,
        idObjetivo: datosIndicadores.idObjetivo,
        tipo: datosIndicadores.tipo,
      }
      this.fetchPuntajesIndicadores(datosPuntajes).then(() => {
        this.puntajesAlumnos = this.getPuntajesIndicadores
        this.mostrarAlumnosIndicadores = true
        this.cargandoAlumnos = false
      })
    },
  },
}
</script>

<style>

</style>
