<template>
  <div
    class="d-flex justify-content-center"
  >
    <div
      v-for="(objetivo, index) in objetivosBtns"
      :key="index"
    >
      <b-button
        :id="`objetivo-${index}-${objetivo.id}`"
        :variant="setColor(objetivo.puntajes_indicadores, objetivo.puntajes_indicadores_personalizado, objetivo.tipo)"
        :class="`${obj_activo === objetivo.id ? 'active' : ''}`"
        class="btn-sm"
        style="padding: 6px 3px 6px 3px !important; margin-right: 3px !important;
        margin-top: 1px !important; min-width: 60px !important"
        :title="objetivo.nombreObjetivo"
        :pill="getUser.rolActivo === 'Jefe UTP' || getUser.rolActivo === 'Director' && objetivo.tipo === 'Interno'"
        @click="$emit('processBtn', objetivo)"
      >
        <span class="align-middle">{{ colorBtn }} {{ objetivo.abreviatura }}</span>
        <br>
        <feather-icon
          v-if="objetivo.priorizacionInterna !== null"
          icon="StarIcon"
          size="12"
          style="margin-top: 2px !important;"
          :class="[{'fill-current': true }]"
        />
        {{
          getUser.rolActivo === 'Jefe UTP' || getUser.rolActivo === 'Director' && objetivo.priorizacion !== null
            ? '|'
            : null
        }}
        <feather-icon
          v-if="getUser.rolActivo === 'Jefe UTP' || getUser.rolActivo === 'Director' && objetivo.priorizacion !== null"
          icon="StarIcon"
          size="12"
          style="margin-top: 2px !important;"
          :class="[{'fill-current': false }]"
        />
      </b-button>

    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: { BButton },
  data() {
    return {
      delay: { show: 1800 },
      isActive: false,
      objetivosBtns: [],
      colorBtn: null,
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
    }),
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    objetivos: {
      type: Array,
      required: true,
    },
    obj_activo: {
      type: Number,
      required: false,
    },
  },
  watch: {
    objetivos() {
      this.setObjetivos()
    },
  },
  mounted() {
    this.setObjetivos()
  },
  methods: {
    setObjetivos() {
      const { key } = this.field
      this.objetivosBtns = this.objetivos.filter(ob => ob.idEje === Number(key))
    },
    setColor(cantidadIndicadoresTrabajados, cantidadIndicadoresTrabajadosPersonalizados, tipo) {
      let colorBtn = null
      if (cantidadIndicadoresTrabajados > 0 || cantidadIndicadoresTrabajadosPersonalizados > 0) {
        colorBtn = 'success'
      } else if (cantidadIndicadoresTrabajados === 0 && cantidadIndicadoresTrabajadosPersonalizados === 0) {
        colorBtn = 'primary'
      } else {
        colorBtn = 'primary'
      }
      if (tipo === 'Interno') {
        if (this.getUser.rolActivo === 'Director' || this.getUser.rolActivo === 'Jefe UTP') {
          colorBtn = `gradient-${colorBtn}`
        } else {
          colorBtn = `relief-${colorBtn}`
        }
      } else {
        colorBtn = `relief-${colorBtn}`
      }

      return colorBtn
    },
  },
}
</script>

