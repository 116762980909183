<template>
  <div>
    <spinner
      v-if="showSpinner"
      size="small"
      class="mt-1 mb-1"
    />
    <div
      v-else
      class="rounded"
      :style="`margin: -2px !important; background: ${color};`"
    >
      <b-form-select
        v-if="$can('update', 'avances')"
        v-model="puntaje"
        placeholder="Selecciona el puntaje"
        style="width: 70px !important; margin-top: 10px !important; margin-bottom: 10px !important; margin-left: 5px !important; margin-right: 5px !important;"
        :options="puntajesOption"
        label="text"
        :title="autores"
        :reduce="option => option.value"
        size="sm"
        variant="info"
        class="mt-1 mb-1"
        @change="changePuntaje"
      />
      <div
        v-else
        :style="`color: ${textColor}`"
      >
        {{ puntaje ? puntaje : '-' }}
      </div>
    </div>
  </div>
</template>

<script>
import { BFormSelect, VBTooltip } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { formatos } from '@core/mixins/ui/formatos'
import spinner from '../../../components/spinner.vue'

export default {
  components: {
    BFormSelect,

    spinner,
  },
  mixins: [formatos],
  directives: {
    'b-tooltip': VBTooltip,
    // Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
    puntajesIndicadoresTransformacion: {
      type: Array,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
    idCursoSelected: {
      type: Number,
      required: true,
    },
    idAsignaturaSelected: {
      type: Number,
      required: true,
    },
    idObjetivo: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showSpinner: false,
      puntaje: null,
      color: null,
      textColor: null,
      puntajesOption: [],
      autores: null,
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  mounted() {
    this.setPuntajesIndicadoresTransformacion(this.puntajesIndicadoresTransformacion)
    if (this.item.puntajesAlumno.length > 0) {
      this.setPuntajes(this.item.puntajesAlumno, this.field)
    }
  },
  methods: {
    ...mapActions({
      updatePuntajeIndicadorPersonalizado: 'puntajesIndicadores/updatePuntajeIndicadorPersonalizado',
    }),
    setColor(puntaje) {
      this.color = null
      this.textColor = null
      const { color, textColor } = this.formatoColoresPuntajes(puntaje)
      this.color = color
      this.textColor = textColor
    },
    setPuntajesIndicadoresTransformacion(puntajesTransformacion) {
      this.puntajesOption = []
      this.puntajesOption.push({
        value: null,
        text: ' - ',
      })
      puntajesTransformacion.forEach(puntajeTransformacion => {
        this.puntajesOption.push({
          value: puntajeTransformacion.puntaje,
          text: puntajeTransformacion.nivelLogro,
        })
      })
    },
    setPuntajes(puntajes, field) {
      if (field.indicadorPersonalizado) {
        let puntajeIndicador = puntajes.filter(p => p.tipoIndicador === 'Personalizado')
        puntajeIndicador = puntajeIndicador.find(p => p.idIndicador === Number(field.key))
        this.puntaje = typeof puntajeIndicador !== 'undefined'
          ? puntajeIndicador.puntaje
          : null

        this.setColor(this.puntaje)
      }
    },
    primeraEnMayus(val) {
      return val.toLowerCase()
        .trim()
        .split(' ')
        .map(v => v[0].toUpperCase() + v.substr(1))
        .join(' ')
    },
    changePuntaje() {
      // this.showSpinner = true
      this.setColor(this.puntaje)
      const data = {
        idPeriodo: this.idPeriodoActivo,
        idCurso: this.idCursoSelected,
        idAsignatura: this.idAsignaturaSelected,
        idObjetivo: this.idObjetivo,
        idIndicador: this.field.key,
        idAlumno: this.item.id,
        tipoObjetivo: this.item.tipoObjetivo,
        puntaje: this.puntaje,
        tipo: this.field.tipoIndicador,
      }

      const {
        text,
        title,
        variant,
      } = this.mensajes()

      this.updatePuntajeIndicadorPersonalizado(data).then(() => {
        const errorPuntajeIndicador = store.state.puntajesIndicadores
        const errorMessage = errorPuntajeIndicador.errorMessage.errors
        if (!errorPuntajeIndicador.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title,
              icon: 'CheckIcon',
              text,
              variant,
            },
          },
          {
            position: 'top-right',
            timeout: 2000,
          })
          this.showSpinner = false
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.showSpinner = false
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.showSpinner = false
        }
      })
    },
    mensajes(puntaje) {
      let text
      let title = 'Puntaje asignado 👍'
      let variant = 'primary'
      if (puntaje === 4) {
        //* title = `PTJ: ${this.puntaje} | NL: L - 6.0 > 7.0`
        title = 'Ha logrado el nivel esperado de aprendizaje!'
        text = this.primeraEnMayus(this.item.nombre)
        variant = 'success'
      } else if (puntaje === 3) {
        //* title = `PTJ: ${this.puntaje} | NL: ML - 5.0 > 5.9`
        title = 'Ha logrado parte del aprendizaje!'
        text = this.primeraEnMayus(this.item.nombre)
        variant = 'warning'
      } else if (puntaje === 2) {
        //* title = `PTJ: ${this.puntaje} | NL: PL - 4.0 > 4.9`
        title = 'Está en proceso de obtener logros de aprendizaje!'
        text = this.primeraEnMayus(this.item.nombre)
        variant = 'warning'
      } else if (puntaje === 1) {
        //* title = `PTJ: ${this.puntaje} | NL: SL - 3.0 > 3.9`
        title = 'No alcanza a obtener logros de aprendizaje!'
        text = this.primeraEnMayus(this.item.nombre)
        variant = 'danger'
      } else if (puntaje === 0) {
        //* title = `PTJ: ${this.puntaje} | NL: N.O. - 2.0 > 2.9`
        title = 'No se observan evidencias para evaluar el aprendizaje!'
        text = this.primeraEnMayus(this.item.nombre)
        variant = 'danger'
      } else if (puntaje === null) {
        title = 'Puntaje eliminado correctamente 👍'
        text = this.primeraEnMayus(this.item.nombre)
      }
      const response = {
        title,
        text,
        variant,
      }
      return response
    },
  },
}
</script>
