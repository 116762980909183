// We haven't added icon's computed property because it makes this mixin coupled with UI
export const formatos = {
  data() {
    return {
      // passwordFieldType: 'password',
    }
  },
  methods: {
    formatoColoresNotas(nota) {
      let color
      let textColor
      if (parseFloat(nota) < 3.0) {
        color = 'red'
        textColor = 'white'
      }
      if (parseFloat(nota) >= 3.0 && parseFloat(nota) < 4.0) {
        color = 'pink'
        textColor = 'black'
      }
      if (parseFloat(nota) >= 4.0 && parseFloat(nota) < 5.0) {
        color = 'orange'
        textColor = 'black'
      }
      if (parseFloat(nota) >= 5.0 && parseFloat(nota) < 6.0) {
        color = 'yellow'
        textColor = 'black'
      }
      if (parseFloat(nota) >= 6.0) {
        color = 'green'
        textColor = 'white'
      }
      if (nota === null) {
        color = 'none'
        // textColor = 'black'
      }
      const arrayResponse = {
        color,
        textColor,
      }
      return arrayResponse
    },
    formatoColoresPuntajes(puntaje) {
      let color
      let textColor
      switch (puntaje) {
        case null:
          color = 'none'
          textColor = 'none'
          break
        case '-':
          color = 'secondary'
          textColor = 'white'
          break
        case 0:
          color = 'red'
          textColor = 'white'
          break
        case 1:
          color = 'pink'
          textColor = 'black'
          break
        case 2:
          color = 'orange'
          textColor = 'black'
          break
        case 3:
          color = 'yellow'
          textColor = 'black'
          break
        case 4:
          color = 'green'
          textColor = 'white'
          break

        default:
          color = 'none'
          break
      }
      const arrayResponse = {
        color,
        textColor,
      }
      return arrayResponse
    },
    divideRut(rut) {
      const rutNum = rut.replace('-', '')
        .replace('.', '').replace('.', '')
      const rutSinDv = rutNum.substring(0, rutNum.length - 1)
      const dv = rutNum.substring(rutNum.length - 1, rutNum.length)
      return {
        rutSinDv,
        dv,
      }
    },
    formatHTMLSweetEliminar(modulo, texto, info) {
      const infoDiv = info !== '' && typeof info !== 'undefined'
        ? `<div
            class="text-secondary mb-75"
            style="font-size: 1.1rem;"
          >
           Info: "${info}"
          </div>`
        : ''
      const textoDiv = texto !== '' && typeof texto !== 'undefined'
        ? `<spam
            class="text-secondary"
            style="font-size: 1.1rem;"
          >
            "${texto}"
          </spam>`
        : ''
      const moduloDiv = modulo !== '' && typeof modulo !== 'undefined'
        ? `<p class="text-center mb-50">
            ¿Está seguro(a) de eliminar ${modulo}?
          </p>`
        : ''
      return `
      <div class="mt-75 mb-75">
        ${infoDiv}
        ${moduloDiv}
        ${textoDiv}
      </div>
      `
    },
    formatHTMLSweetInfo(modulo, info) {
      const infoDiv = info !== ''
        ? `<i
            class="text-secondary"
            style="font-size: 1rem;"
          >
            Info: "${info}"
          </i>`
        : ''
      return `
        <div class="mt-75 mb-50">
          ${infoDiv}
          <p class="mb-1 mt-1 text-center">
            ¿Está seguro(a) de actualizar ${modulo}?
          </p>
        </div>
      `
    },

    // EJEMPLO: Jueves 06 de junio del 2021
    formatFechaVerCompleto(fecha) {
      const dias = [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo',
      ]

      const meses = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciciembre',
      ]

      const date = new Date(fecha)
      const nombreDia = dias[date.getDay()]
      const nombreMes = meses[date.getMonth()]

      const dia = date.getDate() + 1 > 9
        ? date.getDate() + 1
        : `0${date.getDate() + 1}`

      const year = date.getFullYear()

      return `${nombreDia} ${dia} de ${nombreMes} del ${year}`

      // OBTIENE EL NUM DEL MES
      // const mes = (date.getMonth() + 1) > 9
      //   ? (date.getMonth() + 1)
      //   : `0${(date.getMonth() + 1)}`
    },

    // EJEMPLO: Jueves 06 de junio
    formatDiaMesCompleto(fecha) {
      const dias = [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo',
      ]

      const meses = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciciembre',
      ]

      const date = new Date(fecha)
      const nombreDia = dias[date.getDay()]
      const nombreMes = meses[date.getMonth()]

      const dia = date.getDate() + 1 > 9
        ? date.getDate() + 1
        : `0${date.getDate() + 1}`

      return `${nombreDia} ${dia} de ${nombreMes}`
    },

    // EJEMPLO: Jue, 06-06-2021
    formatFechaVer(fecha) {
      const dias = [
        'Lun',
        'Mar',
        'Mié',
        'Jue',
        'Vie',
        'Sáb',
        'Dom',
      ]

      const meses = [
        'ene',
        'feb',
        'mar',
        'abr',
        'may',
        'jun',
        'jul',
        'ago',
        'sep',
        'oct',
        'nov',
        'dic',
      ]

      const date = new Date(fecha)
      const nombreDia = dias[date.getDay()]
      const nombreMes = meses[date.getMonth()]
      const dia = date.getDate() + 1 > 9
        ? date.getDate() + 1
        : `0${date.getDate() + 1}`

      const year = date.getFullYear()

      return `${nombreDia}, ${dia} ${nombreMes} ${year}`

      // OBTIENE EL NUM DEL MES
      // const mes = (date.getMonth() + 1) > 9
      //   ? (date.getMonth() + 1)
      //   : `0${(date.getMonth() + 1)}`
    },

    // EJEMPLO: Jue, 06-06-2021
    formatFechaVerActividades(fecha) {
      const dias = [
        'Lun',
        'Mar',
        'Mié',
        'Jue',
        'Vie',
        'Sáb',
        'Dom',
      ]

      const meses = [
        'ene',
        'feb',
        'mar',
        'abr',
        'may',
        'jun',
        'jul',
        'ago',
        'sep',
        'oct',
        'nov',
        'dic',
      ]
      const date = new Date(fecha)
      const nombreDia = dias[date.getDay() - 1]
      const nombreMes = meses[date.getMonth()]
      const dia = date.getDate() > 9
        ? date.getDate()
        : `0${date.getDate()}`

      const year = date.getFullYear()

      return `${nombreDia}, ${dia} ${nombreMes} ${year}`

      // OBTIENE EL NUM DEL MES
      // const mes = (date.getMonth() + 1) > 9
      //   ? (date.getMonth() + 1)
      //   : `0${(date.getMonth() + 1)}`
    },

    formatoFechaLdConexion(fecha) {
      const fechaSeparada = fecha.split('-')
      const year = fechaSeparada[2].split(' ')
      return `${fechaSeparada[0]}-${fechaSeparada[1]}-${year[0]}`
    },

    // EJEMPLO: Jueves 06
    formatFechaDía(fecha) {
      const dias = [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo',
      ]
      const date = new Date(fecha)
      let nombreDia
      if (date.getDay() === 0) {
        nombreDia = 'Domingo'
      } else {
        nombreDia = dias[date.getDay() - 1]
      }
      const dia = date.getDate() + 1 > 9
        ? date.getDate()
        : `0${date.getDate()}`

      return `${nombreDia} ${dia}`

      // OBTIENE EL NUM DEL MES
      // const mes = (date.getMonth() + 1) > 9
      //   ? (date.getMonth() + 1)
      //   : `0${(date.getMonth() + 1)}`
    },

    // 2023-06-16 22:00
    formatoFechaCalendar(fecha, hora) {
      const date = new Date(fecha)
      const mes = date.getMonth() > 8
        ? date.getMonth() + 1
        : `0${date.getMonth() + 1}`
      const dia = date.getDate() + 1 > 9
        ? date.getDate() + 1
        : `0${date.getDate() + 1}`

      const year = date.getFullYear()

      return `${year}-${mes}-${dia} ${hora}`
    },

    // 2023-06-16
    formatoFechaHoy() {
      const date = new Date()
      const mes = date.getMonth() > 8
        ? date.getMonth() + 1
        : `0${date.getMonth() + 1}`
      const dia = date.getDate() + 1 > 9
        ? date.getDate() + 1
        : `0${date.getDate() + 1}`

      const year = date.getFullYear()

      return `${year}-${mes}-${dia}`
    },

    formatoFechaHoyCalendar() {
      const date = new Date()
      const mes = date.getMonth() > 8
        ? date.getMonth() + 1
        : `0${date.getMonth() + 1}`
      const dia = date.getDate() + 1 > 9
        ? date.getDate()
        : `0${date.getDate()}`

      const year = date.getFullYear()

      return `${year}-${mes}-${dia}`
    },

    // 16-06-2023
    formatoDiaMesYear(fecha) {
      let fechaFinal
      if (fecha !== null) {
        const fechaSeparada = fecha.split('-')
        fechaFinal = `${fechaSeparada[2]}-${fechaSeparada[1]}-${fechaSeparada[0]}`
      }
      return fechaFinal
    },

    // 16-06-2023
    formatoDiaMes(fecha) {
      let fechaFinal
      if (fecha !== null) {
        const fechaSeparada = fecha.split('-')
        fechaFinal = `${fechaSeparada[2]}-${fechaSeparada[1]}`
      }
      return fechaFinal
    },

    // 2023-06-16
    formatoFecha() {
      const date = new Date()
      const mes = date.getMonth() > 8
        ? date.getMonth() + 1
        : `0${date.getMonth() + 1}`
      const dia = date.getDate() > 9
        ? date.getDate()
        : `0${date.getDate()}`

      const year = date.getFullYear()

      return `${year}-${mes}-${dia}`
    },

    // 13:30 hrs
    formatHoraVer(date) {
      const hora = date.split(':')[0]
      const minutos = date.split(':')[1]
      const horario = `${hora}:${minutos} hrs`
      return horario
    },

    // 13:30
    formatHoraInput(date) {
      const hora = date.split(':')[0]
      const minutos = date.split(':')[1]
      if (typeof minutos === 'undefined') {
        // minutos = '00'
      }
      let horario = `${hora}:${minutos}`
      if (minutos === 'undefined') {
        horario = hora
      }

      return horario
    },

    formatSoloNumeros(number) {
      const expReg = /^[0-9]+$/
      let numero
      if (number.match(expReg)) {
        numero = number
      } else if (number === '') {
        numero = 0
      } else {
        numero = 0
      }

      return numero
    },

    // * Solo números
    //* Entre 0 - 100
    formatPorcentajeEvaluacion(number) {
      const expReg = /^[0-9]+$/
      let numero
      if (number.match(expReg)) {
        numero = number
      } else if (number === '') {
        numero = 0
      } else {
        numero = 0
      }
      numero = numero > 100 ? 100 : numero

      return numero
    },

    // * Solo números
    // * Max 6 caracteres
    formatCodigoFirma(txt) {
      const expReg = /^[0-9]+$/
      let numero
      if (txt.match(expReg)) {
        numero = txt
      } else {
        numero = ''
      }

      if (numero.length > 6) {
        numero = numero.slice(0, 6)
      }

      return numero
    },

    formatSoloNumerosUnDigito(number) {
      let unDigito = 0
      unDigito = number[0] > 8 ? '8' : number[0]
      const expReg = /^[0-9]+$/
      let numero
      if (typeof unDigito === 'undefined' || unDigito === '' || unDigito === null) {
        numero = '0'
      } else if (unDigito.match(expReg)) {
        numero = unDigito
      } else {
        numero = '0'
      }

      return numero
    },

    formatSoloNumerosMaxLenght(number, max) {
      const expReg = /^[0-9]*(\.?)[ 0-9]+$/
      const lengthCorte = max
      let numero
      if (number.match(expReg)) {
        numero = number.substring(0, lengthCorte)
      } else {
        numero = number.substring(0, number.length - 1)
        const ultimoCaracter = number.substring(number.length - 2, number.length - 1)
        if (ultimoCaracter === '`'
          || ultimoCaracter === '´'
          || ultimoCaracter === '¨'
          || ultimoCaracter === '^') {
          numero = numero.substring(0, numero.length - 1)
        }
      }
      return numero
    },

    formatNota(n) {
      let number = String(n)
      number = number.replace('.', '')
      if (number.length > 2) {
        number = `${number[0]}${number[1]}`
      }
      if (number.substring(0, 1) === '0') {
        number = number.substring(1)
      }
      if (number === '8' || number === '9') {
        number = '0'
      }
      const expReg = /^[0-9]+$/
      let numero = Number
      if (number.match(expReg)) {
        numero = number
      } else if (number === '') {
        // numero = 0
      } else {
        // numero = 0
      }

      if (numero.length > 2) {
        numero = `${numero[0]}${numero[1]}`
      } else if (numero > 70) {
        numero = 70
      }
      const numeroUno = typeof numero[0] !== 'undefined' ? numero[0] : '0'
      const numeroDos = typeof numero[1] !== 'undefined' ? `. ${numero[1]}` : ''
      numero = `${numeroUno}${numeroDos}`
      return numero
    },

    formatColor(n) {
      const nota = parseInt(n, 0)
      if (nota === 0) {
        this.textColor = 'text-dark'
      } else if (nota >= 1 && nota < 4) {
        this.textColor = 'text-danger'
      } else if (nota > 9 && nota < 40) {
        this.textColor = 'text-danger'
      } else if (nota >= 4 && nota <= 7) {
        this.textColor = 'text-primary'
      } else if (nota > 39 && nota < 71) {
        this.textColor = 'text-primary'
      } else if (nota > 70) {
        this.textColor = 'text-dark'
      }
    },
  },
}

export const _ = null
