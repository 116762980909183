<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
    class="mt-0"
  >

      <!-- striped -->
    <b-table
      small
      hover
      noCollapse
      bordered
      responsive
      sticky-header
      class="mt-0 mb-0 rounded"
      style="min-height: 638px !important; margin-top: -14px !important;"
      :current-page="currentPage"
      :items.sync="items"
      :fields="fields"
    >

      <!-- Header: Check -->
      <!-- <template #head(colCheck)="data">

        <b-form-checkbox
          :id="data.label"
          v-model="chkTodo"
        />

      </template>

      <template #cell(colCheck)="data">

        <b-form-checkbox
          :id="`chk-${data.item.id}`"
          v-model="data.item.chkSelected"
        />

      </template> -->

      <!-- COLUMNAS Indicadores -->
      <template
        #cell()="data"
      >
        <!-- col: NUM LISTA -->
        <div
          v-if="data.field.key == 'numLista'"
        >
          {{ data.item.numLista }}
        </div>

        <!-- col: NOMBRE -->
        <div
          v-else-if="data.field.key == 'nombre'"
        >
          {{ data.item.nombre }}
        </div>

        <!-- col: abreviaturaDiagnostico -->
        <div
          v-else-if="data.field.key == 'abreviaturaDiagnostico'"
        >
          {{ data.item.abreviaturaDiagnostico }}
        </div>

        <!-- col: nombrePrioritario -->
        <div
          v-else-if="data.field.key == 'nombrePrioritario'"
        >
          {{ data.item.nombrePrioritario }}
        </div>

        <!-- col: Puntaje Indicador Ministerio -->
        <puntajeAlumnoIndicador
          v-else-if="data.field.indicador"
          :item.sync="data.item"
          :field="data.field"
          :puntajesIndicadoresTransformacion="getPuntajesIndicadoresTransformacion"
          :idPeriodoActivo="idPeriodoActivo"
          :idCursoSelected="idCursoSelected"
          :idAsignaturaSelected="idAsignaturaSelected"
          :idObjetivo="idObjetivo"
          @changeItem="changeItem"
        />

        <!-- col: Puntaje Indicador Personalizado -->
        <puntajeAlumnoIndicadorPersonalizado
          v-else-if="data.field.indicadorPersonalizado"
          :item.sync="data.item"
          :field="data.field"
          :puntajesIndicadoresTransformacion="getPuntajesIndicadoresTransformacion"
          :idPeriodoActivo="idPeriodoActivo"
          :idCursoSelected="idCursoSelected"
          :idAsignaturaSelected="idAsignaturaSelected"
          :idObjetivo="idObjetivo"
          @changeItem="changeItem"
        />

        <!-- col: Nota -->
        <conversionNotas
          v-else-if="data.field.key == 'nota'"
          :item.sync="data.item"
          :changePuntaje.sync="data.item.changePuntaje"
          :objetivo="objetivo"
          :field="data.field"
          :idPeriodoActivo="idPeriodoActivo"
          :idObjetivo="idObjetivo"
        />

        <!-- nivelLogro -->
        <nivelLogro
          v-else-if="data.field.key == 'nivelLogro'"
          :item.sync="data.item"
          :changePuntaje.sync="data.item.changePuntaje"
          :objetivo="objetivo"
          :field="data.field"
          :idPeriodoActivo="idPeriodoActivo"
          :idObjetivo="idObjetivo"
        />

      </template>

    </b-table>
  </b-overlay>
</template>

<script>
import {
  BTable, BOverlay,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import puntajeAlumnoIndicador from './alumnosIndicador/puntajeAlumnoIndicador.vue'
import puntajeAlumnoIndicadorPersonalizado from './alumnosIndicador/puntajeAlumnoIndicadorPersonalizado.vue'
import conversionNotas from './alumnosIndicador/conversionNotas.vue'
import nivelLogro from './alumnosIndicador/nivelLogro.vue'

export default {
  components: {
    BTable,
    BOverlay,
    puntajeAlumnoIndicador,
    puntajeAlumnoIndicadorPersonalizado,
    conversionNotas,
    nivelLogro,
  },
  props: {
    indicadores: {
      type: Array,
      required: true,
    },
    indicadorPersonalizado: {
      type: Array,
      required: true,
    },
    alumnos: {
      type: Array,
      required: true,
    },
    puntajesAlumnos: {
      type: Array,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
    idCursoSelected: {
      type: Number,
      required: true,
    },
    idAsignaturaSelected: {
      type: Number,
      required: true,
    },
    idObjetivo: {
      type: Number,
      required: true,
    },
    objetivo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cargando: true,
      spinner: false,
      // chk
      items: [],
      selectedchk: [],
      chkTodo: null,
      checked: null,

      changePuntaje: null,

      fieldsIndicadoresPersonalizados: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      color: [
        {
          nombre: '',
        },
        {
          nombre: 'danger',
        },
        {
          nombre: 'warning',
        },
        {
          nombre: 'success',
        },
        {
          nombre: 'info',
        },
      ],
      fields: [],
      field: [
        {
          key: 'numLista',
          label: '#',
          sortable: false,
          variant: 'primary',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: false,
          variant: 'light',
          stickyColumn: true,
          tdClass: 'text-left',
          thStyle: {
            'text-align': 'left',
            width: '300px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'abreviaturaDiagnostico',
          label: 'Diagnóstico Pie',
          sortable: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '30px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombrePrioritario',
          label: '¿Prioritario?',
          sortable: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '30px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldNota: [
        {
          key: 'nota',
          label: 'Nota',
          tdClass: 'text-center',
          variant: 'success',
          thStyle: {
            width: '10px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldNivelLogro: [
        {
          key: 'nivelLogro',
          label: 'Nivel de logro',
          tdClass: 'text-center',
          variant: 'success',
          thStyle: {
            width: '10px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getPuntajesIndicadoresTransformacion: 'puntajesIndicadores/getPuntajesIndicadoresTransformacion',
      getPuntajesIndicadores: 'puntajesIndicadores/getPuntajesIndicadores',
      getIndicadorPersonalizadoObjetivoAprobados: 'indicadorPersonalizado/getIndicadorPersonalizadoObjetivoAprobados',
    }),
  },
  watch: {
    puntajesAlumnos() {
      this.setItems(this.alumnos, this.puntajesAlumnos)
    },
    getIndicadorPersonalizadoObjetivoAprobados() {
      this.indicadorPersonalizado = this.getIndicadorPersonalizadoObjetivoAprobados
      this.setIndicadoresPersonalizados(this.getIndicadorPersonalizadoObjetivoAprobados)
      this.setAll()
    },
  },
  mounted() {
    this.setAll()
  },
  methods: {
    changeItem(data) {
      const itemsChange = this.items.find(i => i.id === Number(data.idAlumno))
      itemsChange.promedioAlumno.nota = data.promedio
    },
    setItems(alumnos, puntajes) {
      // consultar notas por indicador en este curso
      this.items = []
      alumnos.forEach(alumno => {
        const nombre = `${alumno.primerApellido} ${alumno.segundoApellido} ${alumno.nombres}`
        const puntajesAlumno = puntajes.filter(p => p.idAlumno === alumno.id)
        this.items.push({
          idCurso: alumno.idCurso,
          idAsignatura: this.idAsignaturaSelected,
          id: alumno.id,
          numLista: alumno.numLista,
          rut: alumno.rut,
          nombre,
          nombreDiagnostico: alumno.nombreDiagnostico,
          abreviaturaDiagnostico: alumno.abreviaturaDiagnostico,
          nombrePrioritario: alumno.nombrePrioritario,
          changePuntaje: {},
          puntajesAlumno: puntajesAlumno[0].puntajes,
          promedioAlumno: puntajesAlumno[0].promedio,
          nivelLogroAlumno: puntajesAlumno[0].nivel_logro,
          tipoObjetivo: this.objetivo.tipo,
        })
      })

      this.cargando = false
    },
    setAll() {
      this.fields = []
      this.setFields(this.field)
      this.setIndicadores(this.indicadores)
      this.fields.push(this.fieldNota)
      this.fields.push(this.fieldNivelLogro)
      if (this.indicadorPersonalizado) {
        this.setIndicadoresPersonalizados(this.indicadorPersonalizado)
      }
      this.setItems(this.alumnos, this.puntajesAlumnos)
    },
    setFields(fields) {
      fields.forEach(field => {
        this.fields.push(field)
      })
    },
    setIndicadores(indicadores) {
      indicadores.forEach(indicador => {
        const label = indicador.nombre.length > 40
          ? `${indicador.nombre.substr(0, 40)}...`
          : indicador.nombre
        this.fields.push({
          key: `${indicador.id}`,
          label,
          headerTitle: `Indicador:
${indicador.nombre}`,
          tipoIndicador: indicador.tipo,
          tdClass: 'text-center',
          variant: 'secondary',
          indicador: true,
          thStyle: {
            width: '60px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        })
      })
    },
    setIndicadoresPersonalizados(indicadoresPersonalizados) {
      indicadoresPersonalizados.forEach(indicadorPersonalizado => {
        const label = indicadorPersonalizado.nombre.length > 35
          ? `${indicadorPersonalizado.nombre.substr(0, 35)}...`
          : `${indicadorPersonalizado.nombre}`

        // variant: 'secondary',
        const fieldIndicadorPersonalizado = {
          key: `${indicadorPersonalizado.id}`,
          label,
          headerTitle: `Indicador personalizado:
${indicadorPersonalizado.nombre}`,
          tdClass: 'text-center',
          variant: 'primary',
          indicadorPersonalizado: true,
          tipoIndicador: 'Personalizado',
          thStyle: {
            width: '60px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        }
        const columna = this.fields.length - 2
        this.fields.splice(columna, 0, fieldIndicadorPersonalizado)
      })
    },

    // Checkbox select item Table
    chkAll() {
      this.items.forEach(item => {
        const cliente = this.items.find(i => i.id === item.id)
        cliente.chkSelected = this.chkTodo
      })
    },
    chkCount() {
      let chkCount = 0
      this.items.forEach(item => {
        chkCount = item.chkSelected
          ? chkCount + 1
          : chkCount
      })
      return chkCount === 0
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
