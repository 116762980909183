<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-card-title class="mt-1 text-white">
      {{ objetivo.abreviatura }}
      <feather-icon
        v-if="objetivo.priorizacionInterna !== null"
        icon="StarIcon"
        size="18"
        class="fill-current text-white mb-50 ml-25"
      />
    </b-card-title>

    <b-card-text class="mt-2">
      {{ objetivo.nombreObjetivo }}
    </b-card-text>
  </b-overlay>
</template>

<script>
import {
  BCardText, BCardTitle, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BCardTitle,
    BOverlay,
  },
  data() {
    return {
      spinner: null,
    }
  },
  props: {
    objetivo: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
