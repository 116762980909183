<template>
  <div>
    <div
      v-if="nota"
      class="rounded pt-25"
      :style="`margin: -2px !important; background: ${color}; color: ${textColor};  height:26px !important;`"
    >

      {{ nota }}
    </div>
    <spinner
      v-else
      size="small"
      class="mt-1"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { formatos } from '@core/mixins/ui/formatos'
import spinner from '../../../components/spinner.vue'

export default {
  components: {
    spinner,
  },
  mixins: [formatos],
  data() {
    return {
      nota: null,
      color: null,
      textColor: null,
      variant: 'secondary',
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    changePuntaje: {
      type: Object,
      required: false,
    },
    field: {
      type: Object,
      required: true,
    },
    objetivo: {
      type: Object,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
    idObjetivo: {
      type: Number,
      required: true,
    },
  },
  watch: {
    getPuntajesIndicadores(getPuntajesIndicadores) {
      this.setConversionNota(getPuntajesIndicadores)
    },
    changePuntaje(changePuntaje) {
      this.setCambioNota(changePuntaje)
    },
  },
  computed: {
    ...mapGetters({
      getNotaConversion: 'notasConversion/getNotaConversion',
      getPuntajesIndicadores: 'puntajesIndicadores/getPuntajesIndicadores',
    }),
    showSpinner() {
      return false
    },
  },
  mounted() {
    this.setPromedio(this.item.promedioAlumno)
    // this.setConversionNota(this.item.puntajesAlumno)
    // Obtener los datos de la tabla conversion notas y mostrar la nota correspondiente
    // al promedio de los puntajes (cantidad indicadores y puntaje obtenido)
  },
  methods: {
    ...mapActions({
      fetchNotaConversion: 'notasConversion/fetchNotaConversion',
      fetchObjetivosTrabajados: 'objetivos/fetchObjetivosTrabajados',
      updateNota: 'notas/updateNota',
    }),
    setColor(nota) {
      this.color = null
      this.textColor = null
      const { color, textColor } = this.formatoColoresNotas(nota)
      this.color = color
      this.textColor = textColor
    },
    setCambioNota(changePuntaje) {
      if (this.item.id === changePuntaje.idAlumno) {
        const indicador = this.item.puntajesAlumno.find(pa => pa.idIndicador === changePuntaje.idIndicador)
        indicador.puntaje = changePuntaje.puntaje
        this.setConversionNota(this.item.puntajesAlumno)
      }
    },
    setNota(cantidadIndicadores, puntajeObtenido) {
      const data = {
        cantidadIndicadores,
        puntajeObtenido,
      }
      this.fetchNotaConversion(data).then(() => {
        if (this.getNotaConversion === undefined) {
          this.nota = '2.0'
          this.setColor(this.nota)
        } else {
          const nota = parseFloat(this.getNotaConversion)
          if (typeof nota === 'number') {
            this.nota = nota.toString().length === 1 ? `${nota}.0` : nota
            this.setColor(nota)
          } else {
            this.nota = '-'
            this.setColor(null)
          }
        }
      })
    },
    setConversionNota(puntajes) {
      const { promedio } = puntajes.find(p => p.idAlumno === this.item.id)
      if (this.item.promedioAlumno.nota !== promedio.nota) {
        if (this.item.promedioAlumno === '-' || this.item.promedioAlumno === 'undefined') {
          this.item.promedioAlumno = {}
        }
        this.setPromedio(promedio)
        const nota = typeof promedio.nota === 'undefined' ? 0 : promedio.nota
        const data = {
          idAlumno: this.item.id,
          idPeriodo: this.idPeriodoActivo,
          idCurso: this.item.idCurso,
          idAsignatura: this.item.idAsignatura,
          idObjetivo: this.idObjetivo,
          idEstablecimiento: this.objetivo.idEstablecimiento,
          nota,
        }
        this.updateNota(data)
      }
    },
    setPromedio(promedio) {
      this.nota = null
      this.nota = promedio.nota
      const nota = parseFloat(this.nota)
      if (typeof promedio.nota !== 'undefined') {
        this.nota = nota.toString().length === 1 ? `${nota}.0` : nota
        this.setColor(nota)
        // const dataos = {
        //   idAlumno: this.item.id,
        //   idPeriodo: this.idPeriodoActivo,
        //   idCurso: this.item.idCurso,
        //   idAsignatura: this.item.idAsignatura,
        //   idObjetivo: this.idObjetivo,
        //   idEstablecimiento: this.objetivo.idEstablecimiento,
        //   nota,
        // }
        // this.updateNota(dataos)
      } else {
        this.nota = '-'
        this.setColor(null)
      }
    },
  },
}
</script>
