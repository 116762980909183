<template>
  <b-row>
    <b-col
      lg="2"
      md="3"
      sm="3"
      class="my-1"
    >
      <!-- BOTON MOSTRAR -->
      <!-- <btnMostrar
        :pageOptions.sync="pageOptions"
        :perPage.sync="perPage"
        :total.sync="getIndicadores.length"
      /> -->
    </b-col>
    <b-col
      lg="6"
      md="5"
      sm="5"
      class="my-1"
    >
      <!-- FILTRO -->
      <!-- <inputFiltro
        :filter.sync="filter"
      /> -->
    </b-col>
    <b-col
      md="4"
      sm="4"
    >
      <!-- BOTON CREAR -->
      <btnModalCreate
        v-if="$can('create', 'indicadoresPersonalizados')"
        class="text-right mr-1 mb-1"
        :objetivo="objetivo"
        :idCurso="idCurso"
      />
    </b-col>

    <b-col cols="12">
      <b-table
        v-if="getIndicadorPersonalizado.length"
        outlined
        striped
        hover
        responsive
        class="mb-2 mt-0"
        :per-page="perPage"
        :current-page="currentPage"
        :items="getIndicadorPersonalizado"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <!-- <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template> -->

        <!-- Column: Usuario Creador -->
        <template #cell(nombreCreador)="data">
          {{ data.item.nombreCreador }}
          {{ data.item.primerApellidoCreador}}
          {{ data.item.segundoApellidoCreador}}
        </template>

        <!-- Column: Usuario Editor -->
        <template #cell(nombreEditor)="data">
          {{ data.item.nombreEditor }}
          {{ data.item.primerApellidoEditor}}
          {{ data.item.segundoApellidoEditor}}
        </template>

        <!-- COLUMNA ESTADO -->
        <template #cell(estado)="data">
          {{ data.item.estado }}
        </template>

        <!-- Column: Action -->
        <template #cell(acciones)="data">

          <btnModalUpdateDelete
            v-if="$can('update', 'indicadoresPersonalizados')"
            class="text-right"
            :objetivo="objetivo"
            :indicador="data.item"
            :idCurso="idCurso"
            @processRemove="remove(data.item)"
          />

        </template>
      </b-table>
      <b-alert
        v-else
        show
        fade
        class="mb-2 mr-1 ml-1"
        variant="info"
      >
        <div class="alert-body">
          <span>No existen indicadores creados para el objetivo seleccionado en el periodo actual.</span>
        </div>
      </b-alert>
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable, BRow, BCol, BAlert,
  // BPagination,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import inputFiltro from '../../../../components/List/inputFiltro.vue'
// import colAccionesBtnes from '../../../../components/List/colAccionesBtnes.vue'
import btnModalCreate from './btnModalCreate.vue'
import btnModalUpdateDelete from './btnModalUpdateDelete.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BAlert,
    // BPagination,

    btnModalCreate,
    btnModalUpdateDelete,
    // colAccionesBtnes,
  },
  data() {
    return {
      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: false,
          thStyle: {
            width: '500px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreCreador',
          label: 'Creador',
          sortable: false,
          thStyle: {
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'created_at',
        //   label: 'Fecha Creación',
        //   sortable: false,
        //   thStyle: {
        //     width: '150px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
        {
          key: 'nombreEditor',
          label: 'Última Edición',
          sortable: false,
          thStyle: {
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'updated_at',
        //   label: 'Fecha Última Edición',
        //   sortable: false,
        //   thStyle: {
        //     width: '150px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
        // {
        //   key: 'estado',
        //   label: 'Estado',
        //   sortable: true,
        //   thStyle: {
        //     width: '130px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ getIndicadorPersonalizado: 'indicadorPersonalizado/getIndicadorPersonalizadoAll' }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  props: {
    objetivo: {
      type: Object,
      required: true,
    },
    idCurso: {
      type: Number,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
    idAsignaturaSelected: {
      type: Number,
      required: true,
    },
  },
  watch: {
    getIndicadorPersonalizado(val) {
      this.totalRows = val.length
      this.setTableList()
    },
  },
  mounted() {
    const data = {
      idObjetivo: this.objetivo.id,
      idPeriodo: this.idPeriodoActivo,
      idCurso: this.idCurso,
      tipoObjetivo: this.objetivo.tipo,
    }
    this.fetchIndicadoresPersonalizadosObjetivo(data)
  },
  methods: {
    ...mapActions({
      fetchIndicadoresPersonalizadosObjetivo: 'indicadorPersonalizado/fetchIndicadoresPersonalizadosObjetivo',
      fetchIndicadoresPersonalizadosObjetivoAprobados: 'indicadorPersonalizado/fetchIndicadoresPersonalizadosObjetivoAprobados',
      updateIndicadorEstado: 'indicadores/updateIndicadorEstado',
      removeIndicadores: 'indicadorPersonalizado/removeIndicador',
      fetchNotasAsignatura: 'notas/fetchNotasAsignatura',
      calcularNotaCurso: 'notas/calcularNotaCurso',
      fetchPuntajesIndicadores: 'puntajesIndicadores/fetchPuntajesIndicadores',
    }),
    ...mapMutations('indicadores', ['setIndicador']),
    setTableList() {
      if (this.$can('update', 'avances')) {
        this.fields.push(this.fieldAcciones)
      }
    },
    addIndicador() {
      this.$router.replace({
        name: 'indicadores-create',
      })
    },
    updateEstado(curso) {
      this.$swal({
        title: 'Cambio de Estado!',
        text: `Estás seguro que deseas cambiar el estado de "${curso.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, cambialo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateIndicadorEstado(curso)
          this.$swal({
            icon: 'success',
            title: 'Estado actualizado!',
            text: `Se ha actualizado el estado de "${curso.nombre}"!`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    remove(indicadorPersonalizado) {
      this.$swal({
        title: 'Eliminar indicador!',
        text: 'Estás seguro que deseas eliminar el indicador?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeIndicadores(indicadorPersonalizado.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Indicador eliminado 👍',
                icon: 'CheckIcon',
                text: 'El indicador fue eliminado con éxito!',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
              timeout: 4000,
            })

            const dataIndicadores = {
              idObjetivo: this.objetivo.id,
              idPeriodo: this.idPeriodoActivo,
              idCurso: this.idCurso,
            }
            this.fetchIndicadoresPersonalizadosObjetivoAprobados(dataIndicadores)
            this.fetchIndicadoresPersonalizadosObjetivo(dataIndicadores)

            // RECALCULA NOTAS
            // const data = {
            //   idPeriodo: this.idPeriodoActivo,
            //   idCurso: this.idCurso,
            //   idAsignatura: this.idAsignaturaSelected,
            //   idObjetivo: this.objetivo.id,
            // }

            // this.calcularNotaCurso(data).then(() => {
            //   this.fetchPuntajesIndicadores(data)
            //   const dataFetchNotas = {
            //     idPeriodo: this.idPeriodoActivo,
            //     idCurso: this.idCurso,
            //     idAsignatura: this.idAsignaturaSelected,
            //   }
            //   this.fetchNotasAsignatura(dataFetchNotas)
            // })
          })
        }
      })
    },
    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
