<template>
  <indicadoresList
    :objetivo="objetivo"
    :idCurso="idCurso"
    :idPeriodoActivo="idPeriodoActivo"
    :idAsignaturaSelected="idAsignaturaSelected"
  />
</template>

<script>
import indicadoresList from './components/IndicadoresList.vue'

export default {
  components: {
    indicadoresList,
  },
  created() {
    // Consultar autenticación del
  },
  props: {
    objetivo: {
      type: Object,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
    idCurso: {
      type: Number,
      required: true,
    },
    idAsignaturaSelected: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style>

</style>
