<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
      <!-- v-ripple.400="'rgba(113, 102, 240, 0.15)'" -->
    <b-button
      id="toggle-btn"
      v-b-modal.modal-crear-indicador-personalizado
      class="btn-sm"
      variant="outline-primary"
    >
      <span class="text-nowrap">Crear Indicador</span>
    </b-button>

    <!-- modal -->
    <b-modal
      id="modal-crear-indicador-personalizado"
      ref="my-modal"
      title="Crear indicador personalizado"
      ok-title="Crear"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          :state="nombreState"
          label="Nombre indicador"
          label-for="nombre-input"
          invalid-feedback="El campo nombre indicador es requerido"
        >
          <b-form-input
            id="nombre-input"
            v-model="nombre"
            :state="nombreState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>

  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton, BFormGroup, BFormInput, BModal, VBModal,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from 'vue-ripple-directive'

// import spinner from '../../components/spinner.vue'
// import puntajeAlumnoIndicador from '../alumnosIndicador/puntajeAlumnoIndicador.vue'

export default {
  components: {
    BOverlay,

    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    // spinner,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    objetivo: {
      type: Object,
      required: true,
    },
    idCurso: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cargando: true,
      spinner: false,

      nombre: '',
      nombreState: null,
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      // getPeriodos: 'periodos/getPeriodos',
      // getPuntajesIndicadoresTransformacion: 'puntajesIndicadores/getPuntajesIndicadoresTransformacion',
    }),
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      createIndicador: 'indicadorPersonalizado/addIndicadorPersonalizado',
      fetchIndicadoresPersonalizadosObjetivo: 'indicadorPersonalizado/fetchIndicadoresPersonalizadosObjetivo',
      fetchIndicadoresPersonalizadosObjetivoAprobados: 'indicadorPersonalizado/fetchIndicadoresPersonalizadosObjetivoAprobados',
    }),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nombreState = valid
      return valid
    },
    resetModal() {
      this.nombre = ''
      this.nombreState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })

      const { idPeriodoActivo } = this.getUser.establecimientos.find(e => e.idEstablecimiento === this.getUser.idEstablecimientoActivo)
      const indicador = {
        nombre: this.nombre,
        idObjetivo: this.objetivo.id,
        idPeriodo: idPeriodoActivo,
        idUsuario: this.getUser.id,
        idCurso: this.idCurso,
        tipoObjetivo: this.objetivo.tipo,
      }

      this.createIndicador(indicador).then(() => {
        const errorIndicadorPersonalizado = store.state.indicadorPersonalizado
        // const errorMessage = errorIndicadorPersonalizado.errorMessage.errors
        if (!errorIndicadorPersonalizado.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Indicador creado 👍',
              icon: 'CheckIcon',
              text: 'El nuevo indicador fue creado con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.fetchIndicadoresPersonalizadosObjetivo(indicador)
          this.fetchIndicadoresPersonalizadosObjetivoAprobados(indicador)
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
