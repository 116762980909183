<template>
  <div
    style="margin: -2px !important; "
  >
    {{ nivelLogro }}
    <spinner
      v-if="!nivelLogro"
      size="small"
      class="mt-1"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import spinner from '../../../components/spinner.vue'

export default {
  components: {
    spinner,
  },
  data() {
    return {
      nivelLogro: null,
      variant: 'secondary',
      conversionNivelLogro: [
        {
          nivelLogro: 'N.O.',
          desde: '2.0',
          hasta: '2.9',
        },
        {
          nivelLogro: 'SL',
          desde: '3.0',
          hasta: '3.9',
        },
        {
          nivelLogro: 'PL',
          desde: '4.0',
          hasta: '4.9',
        },
        {
          nivelLogro: 'ML',
          desde: '5.0',
          hasta: '5.9',
        },
        {
          nivelLogro: 'L',
          desde: '6.0',
          hasta: '7.0',
        },
      ],
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    changePuntaje: {
      type: Object,
      required: false,
    },
    field: {
      type: Object,
      required: true,
    },
    objetivo: {
      type: Object,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
    idObjetivo: {
      type: Number,
      required: true,
    },
  },
  watch: {
    getPuntajesIndicadores(getPuntajesIndicadores) {
      this.setConversionNota(getPuntajesIndicadores)
    },
    changePuntaje(changePuntaje) {
      this.setCambioNota(changePuntaje)
    },
  },
  computed: {
    ...mapGetters({
      getNotaConversion: 'notasConversion/getNotaConversion',
      getPuntajesIndicadores: 'puntajesIndicadores/getPuntajesIndicadores',
    }),
  },
  mounted() {
    this.setNivelLogro(this.item.promedioAlumno)
    // this.setConversionNota(this.item.puntajesAlumno)
    // Obtener los datos de la tabla conversion notas y mostrar la nota correspondiente
    // al promedio de los puntajes (cantidad indicadores y puntaje obtenido)
  },
  methods: {
    ...mapActions({
      fetchNotaConversion: 'notasConversion/fetchNotaConversion',
    }),
    setCambioNota(changePuntaje) {
      if (this.item.id === changePuntaje.idAlumno) {
        const indicador = this.item.puntajesAlumno.find(pa => pa.idIndicador === changePuntaje.idIndicador)
        indicador.puntaje = changePuntaje.puntaje
        this.setConversionNota(this.item.puntajesAlumno)
      }
    },
    setNota(cantidadIndicadores, puntajeObtenido) {
      const data = {
        cantidadIndicadores,
        puntajeObtenido,
      }
      this.fetchNotaConversion(data).then(() => {
        if (this.getNotaConversion === undefined) {
          const nota = '2.0'
          this.setNivelLogro(nota)
        } else {
          const nota = parseFloat(this.getNotaConversion)
          if (typeof nota === 'number') {
            this.setNivelLogro(nota)
          }
        }
      })
    },
    setConversionNota(puntajes) {
      const { promedio } = puntajes.find(p => p.idAlumno === this.item.id)
      if (this.item.promedioAlumno.nota !== promedio.nota) {
        if (this.item.promedioAlumno === '-' || this.item.promedioAlumno === 'undefined') {
          this.item.promedioAlumno = {}
        }
        this.setNivelLogro(promedio)
        this.item.promedioAlumno.nota = promedio.nota
      }
    },
    setNivelLogro(nota) {
      this.nivelLogro = null
      if (typeof nota.nota !== 'undefined') {
        this.conversionNivelLogro.forEach(conv => {
          if (parseFloat(nota.nota) >= parseFloat(conv.desde) && parseFloat(nota.nota) <= parseFloat(conv.hasta)) {
            this.nivelLogro = conv.nivelLogro
          }
        })
      } else {
        this.nivelLogro = '-'
      }
    },
  },
}
</script>
