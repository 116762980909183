<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <div v-if="cargando" class="text-center my-1 mt-2">
      <spinner size="medium"/>
      <p class="text-center mb-2">
        <b-card-text class="mb-1">
          Cargando ejes y objetivos...
        </b-card-text>
      </p>
    </div>
    <div v-else>
    <b-row
      class="my-1"
    >
      <b-col>
        <b-badge
          variant="light-secondary"
          class="pr-1 font-small-2 border-right mr-25 ml-75 pt-25 pb-25 mt-0"
        >
          <b-button
            variant="primary"
            class="btn-sm ml-25 mr-25 mt-25 mb-25"
            disabled
          />
          Sin puntajes
        </b-badge>
        <b-badge
          variant="light-secondary"
          class="pr-1 font-small-2 border-right ml-75 pt-25 pb-25 mt-25 mt-md-0"
        >
          <b-button
            variant="success"
            class="btn-sm ml-25 mr-25 mt-25 mb-25"
            disabled
          />
            Con puntajes
        </b-badge>
        <b-badge
          v-if="getUser.rolActivo === 'Director' || getUser.rolActivo === 'Jefe UTP'"
          variant="light-secondary"
          class="pr-1 font-small-2 border-right ml-75 pt-25 pb-25 mt-25 mt-md-0"
        >
          <b-button
            variant="gradient-secondary"
            class="btn-sm ml-25 mr-25 mb-25 mt-25"
            pill
            disabled
          />
          OAs internos
        </b-badge>
        <b-badge
          v-if="getUser.rolActivo === 'Director' || getUser.rolActivo === 'Jefe UTP'"
          variant="light-secondary"
          class="pr-1 font-small-2 border-right ml-75 pt-25 pb-25 mt-25 mt-md-0"
        >
          <b-button
            variant="relief-secondary"
            class="btn-sm ml-25 mr-25 mb-25 mt-25"
            disabled
          />
          OAs Ministerio
        </b-badge>
      </b-col>
      <b-col
        class="text-right"
      >

        <b-badge
          variant="light-secondary"
          class="pr-1 font-small-2 border-right mr-1 pt-50"
        >
          <feather-icon
            icon="StarIcon"
            size="14"
            :class="[{'fill-current': true }
              , 'text-dark'
            ]"
          />
          Priorizado {{ getUser.rolActivo === 'Director' || getUser.rolActivo === 'Jefe UTP' ? 'Interno' : '' }}
        </b-badge>
        <b-badge
          v-if="getUser.rolActivo === 'Director' || getUser.rolActivo === 'Jefe UTP'"
          variant="light-secondary"
          class="pr-1 font-small-2 mr-1 pt-50 mt-25 mt-md-0"
        >
          <feather-icon
            icon="StarIcon"
            size="14"
            :class="[{'fill-current': false }
              , 'text-dark'
            ]"
          />
          Priorizado Ministerio
        </b-badge>
      </b-col>
    </b-row>
    <b-table
      small
      hover
      noCollapse
      class="mt-50 mb-0"
      responsive
      :fields="!cargando ? fields : []"
      :items.sync="items"
      :bordered="true"
    >

      <!-- Column: OBJETIVOS -->
      <template #cell()="data">
        <btnObjetivos
          :field.sync="data.field"
          :objetivos.sync="objetivos"
          :obj_activo.sync="obj_activo"
          @processBtn="seleccionaObjetivo"
        />
      </template>

    </b-table>
    </div>

  </b-overlay>
</template>

<script>
import {
  BTable, BOverlay, BBadge, BButton, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

import spinner from '../../components/spinner.vue'
import btnObjetivos from '../../components/List/btnObjetivos.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    BBadge,
    BCardText,
    spinner,
    btnObjetivos,
  },
  data() {
    return {
      cargando: true,
      spinner: false,
      objetivos: [],
      colorBtn: 'relief-primary',
      items: [{
      }],
      obj_activo: null,

      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [],
    }
  },
  props: {
    idAsignatura: {
      type: Number,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
    idCursoSelected: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getEjesAsignatura: 'ejes/getEjesAsignatura',
      getObjetivosAsignatura: 'objetivos/getObjetivosAsignatura',
      getObjetivosTrabajados: 'objetivos/getObjetivosTrabajados',
      getPuntajesIndicadores: 'puntajesIndicadores/getPuntajesIndicadores',
    }),
  },
  watch: {
    idAsignatura() {
      this.initialize()
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions({
      fetchEjesAsignatura: 'ejes/fetchEjesAsignatura',
      fetchObjetivosAsignatura: 'objetivos/fetchObjetivosAsignatura',
      fetchObjetivosTrabajados: 'objetivos/fetchObjetivosTrabajados',
    }),
    async initialize() {
      this.cargando = true
      await Promise.all([
        this.loadEjes(),
        this.loadObjetivos()
      ])
      this.loadObjetivosTrabajados()
      this.cargando = false
    },
    async loadEjes() {
      await this.fetchEjesAsignatura(this.idAsignatura)
    },
    async loadObjetivos() {
      const params = {
          idAsignatura: this.idAsignatura,
          idPeriodoActivo: this.idPeriodoActivo,
        }
      const response = await this.fetchObjetivosAsignatura(params)
      await this.setObjetivos(response)
    },
    async loadObjetivosTrabajados() {
      const params = {
        idAsignatura: this.idAsignatura,
        idPeriodo: this.idPeriodoActivo,
        idCurso: this.idCursoSelected,
        objetivos: this.getObjetivosAsignatura,
      }
      const { status, data } = await this.fetchObjetivosTrabajados(params)
      if (status === 'success') {
        this.setObjetivosColor(data)
      }
    },
    seleccionaObjetivo(objetivo) {
      this.obj_activo = objetivo.id
      this.$emit('showObjetivo', objetivo)
      this.$emit('showAlumnosIndicadores', objetivo)
    },
    setEje(eje) {
      this.fields.push({
        key: `${eje.id}`,
        label: eje.nombre,
        sortable: false,
        tdClass: 'text-center',
        thStyle: {
          'text-align': 'center',
          width: '100px !important',
          display: 'table-cell',
          'vertical-align': 'middle',
        },
      })
    },
    setObjetivos(objetivos) {
      this.objetivos = []
      objetivos.sort((a, b) => (a.abreviatura.localeCompare(b.abreviatura)))
      this.objetivos = objetivos
      let idEje
      this.fields = []
      this.objetivos.forEach(objetivo => {
        if (idEje !== objetivo.idEje) {
          idEje = objetivo.idEje
          this.setEje(this.getEjesAsignatura.find(e => e.id === idEje))
        }
      })
    },

    setObjetivosColor(objetivos) {
      objetivos.forEach(objetivo => {
        const obj = this.objetivos.find(o => o.id === objetivo.id)
        obj.puntajes_indicadores = objetivo.puntajes_indicadores
        obj.puntajes_indicadores_personalizado = objetivo.puntajes_indicadores_personalizado
      })
      this.objetivos.forEach(objetivo => {
        const obj = objetivos.find(o => o.id === objetivo.id)
        if (typeof obj === 'undefined') {
          const objchange = this.objetivos.find(o => o.id === objetivo.id)
          objchange.puntajes_indicadores = 0
          objchange.puntajes_indicadores_personalizado = 0
        }
      })
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
